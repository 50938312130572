const IconGooglePlay = () => (
    <svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.4467 12.9612L5.44547 5.47616C5.37508 5.66388 5.30469 5.85159 5.30469 6.1097V19.8831C5.30469 20.1412 5.37508 20.3289 5.44547 20.5166L13.4467 12.9612Z"
            fill="currentColor"
        />
        <path
            d="M14.3853 12.9612L17.0602 15.4484L19.9932 13.8998C20.9318 13.407 20.9318 12.5858 19.9932 12.093L16.9898 10.474L14.3853 12.9612Z"
            fill="currentColor"
        />
        <path
            d="M13.916 12.5154L16.3797 10.1455L6.97064 5.17114C6.57175 4.98342 6.17286 4.91303 5.91475 5.05382L13.916 12.5154Z"
            fill="currentColor"
        />
        <path
            d="M13.916 13.3836L5.91475 20.9155C6.17286 21.0329 6.57175 20.9859 7.04103 20.7982L16.4971 15.8238L13.916 13.3836Z"
            fill="currentColor"
        />
    </svg>
);

export default IconGooglePlay;
